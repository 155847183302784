<template>
  <div>
    <div class="h-8 w-50 loading" />

    <ul class="space-y-6 mt-5">
      <li class="mt-2 w-full flex space-x-2">
        <div class="w-24 h-24 rounded-xs loading" />
        <div class="flex flex-col flex-1 space-y-2 w-full">
          <div class="w-3/4 h-6 loading" />
          <div class="flex space-x-5 w-full">
            <div class="w-10 h-4 loading" />
            <div class="w-10 h-4 loading" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="postcss" scoped>
ul > li:not(:last-child) {
  @apply pb-5 border-b border-gray-300;
}
</style>
